import React, { useEffect, useState } from "react";
import { ArrowLeftIcon , XMarkIcon} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import CustomBuyForMeCard from "./customBuyForMeCard";
import { getShopCartItemById } from "../../redux/reducer/shopCartReducer";
import CustomBuyForMeButton from "./customButtonBuyForMe";
import { submitItemsToPricing, deleteShopCartItem } from "../../redux/reducer/shopCartReducer";
import PaymentDetails from "../ordersForBuyForMe/paymentDetials";
import NodeTitle from "../ordersForBuyForMe/noteTitle";
import CustomPrivacySection from "../ordersForBuyForMe/customPrivacySection";
import CustomBuyForMeButtonOrders from "../ordersForBuyForMe/customOrderBuyButton";
import PaymentForm from "../accountUser/accountPaymentSection";
import CustomOrderInstructionCard from "../ordersForBuyForMe/customOrderInstructionCard";
import { useTranslation } from "react-i18next";
const ItemsBuyMeSection = () => {
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]); // State to track selected item IDs
  const [hasPricedItem, setHasPricedItem] = useState(false); // State to track if any item is "Priced"
  const [timeRemaining, setTimeRemaining] = useState(3600); // 1 hour in seconds
  const [showPaymentForm, setShowPaymentForm] = useState(false); // State to toggle sections
  const {t} =useTranslation();
  const handleButtonClick = () => {
    setShowPaymentForm(true); // Show PaymentForm when button is clicked
  };

  const { items, loading, error } = useSelector((state) => state.shopCart);

  useEffect(() => {
    dispatch(getShopCartItemById());
  }, [dispatch]);

  useEffect(() => {
    // Check if there is any item with status "Priced"
    const pricedItemExists = items?.some((item) => item.status === "Priced");
    setHasPricedItem(pricedItemExists);
  }, [items]);

  // Start countdown timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0; // Stop at 0 seconds
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  // Convert time to HH:MM:SS format
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  // Calculate total price and total shipping fees
  const totals = items?.reduce(
    (acc, item) => {
      const itemPrice = item.price || 0; // Default to 0 if price is undefined
      const shippingFees = item.DShippingFees || 0; // Default to 0 if DShippingFees is undefined

      acc.totalPrice += itemPrice;
      acc.totalFees += shippingFees;
      return acc;
    },
    { totalPrice: 0, totalFees: 0 }
  );

  const handleSelectItem = (itemId) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(itemId)
        ? prevSelectedIds.filter((id) => id !== itemId)
        : [...prevSelectedIds, itemId]
    );
  };

  const handleDelete = (itemId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      dispatch(deleteShopCartItem(itemId))
        .unwrap()
        .then(() => {
          alert("Item deleted successfully!");
          dispatch(getShopCartItemById()); // Fetch updated items
        })
        .catch((error) => alert(error || "Failed to delete item."));
    }
  };

  const handleSubmit = () => {
    if (selectedIds.length > 0) {
      dispatch(submitItemsToPricing(selectedIds));
    } else {
      alert("Please select at least one item.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!items) {
    return <div>not found products</div>;
  }
  if (error) {
    return <div>not found products</div>;
  }

  return (
    <div>
    <div>
      <div className={`${items?.length > 0 ? "grid grid-cols-1 gap-4  md:mt-20 md:grid-cols-2 lg:grid-cols-3 " : "w-full flex flex-col items-center justify-center mt-20"}`}>
        {items?.length > 0 ? (
          items.map((item) => (
            <div key={item._id}>
              <CustomBuyForMeCard
                image={item.images?.[0]?.url || "https://www.markaship.com/uploads/waiting.png"}
                title={item.name || "Product Title"}
                size={item.productSize || "N/A"}
                link={item.productLink}
                count={item.productCount || "1"}
                price={item.price || "0.00"}
                status={item.status || "pending"}
                isEditable={true}
                checked={selectedIds.includes(item._id)}
                onChange={() => handleSelectItem(item._id)}
                del={() => handleDelete(item._id)}
              />
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center w-full gap-4">
            <div className="flex items-center justify-center">
              <XMarkIcon className="w-5 h-5 me-1" />
              <span className="text-xl font-medium">{t("CartEmpty")}</span>
            </div>
            <div className="flex items-center justify-center">
              {t("AddProductsCart")}
            </div>
          </div>
        )}
      </div>
    </div>


      {/* Display Total Details */}
      {/* {items?.length > 0 && (
        <div className="p-4 mt-4 bg-gray-100 rounded-md">
          <h3 className="text-lg font-bold">Summary:</h3>
          <p className="text-md">Total Price: {totals.totalPrice} USD</p>
          <p className="text-md">Total Shipping Fees: {totals.totalFees} USD</p>
          <p className="font-bold text-md">
            Grand Total: {totals.totalPrice + totals.totalFees} USD
          </p>
        </div>
      )} */}

  {/* Conditionally Render PaymentDetails */}
{hasPricedItem && (
  <div className="w-full p-6 mt-4">
    <PaymentDetails 
      time={formatTime(timeRemaining)}
      status="Priced"
      priceInside="غير محدد"
      totalPrice={totals.totalPrice}
      totalPriceWithFees={totals.totalFees}
      finalPrice={totals.totalPrice + totals.totalFees}
    />
     {showPaymentForm ? (
        <PaymentForm />
      ) : (
        <CustomPrivacySection />
      )}

      {/* Button to Trigger Section Replacement */}
      <div className="mt-4">
        <CustomBuyForMeButtonOrders
          children={t( "proceed_to_payment")}
          icon={ArrowLeftIcon}
          bgColor="bg-custom-yellow"
          onClick={handleButtonClick}
        />
      </div>
  </div>
)}

      {/* Custom Button */}
        {/* <CustomBuyForMeButton
          children={t("send_for_pricing")}
          icon={ArrowLeftIcon}
          onClick={handleSubmit} // Trigger the submit when the button is clicked
        /> */}
        <div className='gap-5 md:flex'>
          <CustomOrderInstructionCard
          title={t( "order_instruction_1")}  />
          <CustomOrderInstructionCard
          title={t("order_instruction_2")} />
      </div>
    </div>
  );
};

export default ItemsBuyMeSection;
