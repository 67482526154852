import React, { useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';

const DetailsTable = ({ details, onDelete, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form, setForm] = useState({
    name: '',
    weight: '',
    price: '',
    DShippingFees: '',
    timeLeft: '',
    category: '',
    brand: '',
    area: '',
    currency: '',
  });

  // Extract button from link
  const getLinkButton = (url) => {
    try {
      const hostname = new URL(url).hostname; // ex: us.puma.com
      const parts = hostname.split('.');
      const text = parts[1] || hostname; // Get second part after first dot
      return (
        <button
          onClick={() => window.open(url, '_blank')}
          className="px-2 py-1 font-bold text-black duration-300 rounded bg-custom-yellow hover:bg-yellow-200"
        >
          {text}
        </button>
      );
    } catch {
      return 'Invalid Link';
    }
  };

  const values = [
    details.number,
    details.Name,
    details.phone,
    details.Box,
    getLinkButton(details.link),
    details.Size,
    details.Count,
    details.history ? new Date(details.history).toISOString().slice(0, 10) : ''
  ];

  const handleEdit = () => {
    setForm({
      name: details.Name,
      weight: details.weight || '',
      price: details.price || '',
      DShippingFees: details.DShippingFees || '',
      timeLeft: details.timeLeft || '',
      category: details.category || '',
      brand: details.brand || '',
      area: details.area || '',
      currency: details.currency || '',
    });
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(form);
    setIsEditing(false);
  };

  return (
    <>
      <tr className="text-sm text-center text-gray-800 bg-white">
        {values.map((value, index) => (
          <td key={index} className="px-4 py-2">
            {value}
          </td>
        ))}
        <td className="px-4 py-2">
          <div className="flex justify-between space-x-2">
            <button onClick={handleEdit} className="">
              <PencilSquareIcon className="w-5 h-5 text-black" />
            </button>
            <button onClick={onDelete} className="">
              <TrashIcon className="w-5 h-5 text-black" />
            </button>
          </div>
        </td>
      </tr>

      {isEditing && (
        <tr>
          <td colSpan="100%">
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-4 p-6 bg-white rounded-lg shadow-lg w-[90%] max-w-2xl"
              >
                {[
                  'name',
                  'weight',
                  'price',
                  'DShippingFees',
                  'timeLeft',
                  'category',
                  'brand',
                  'area',
                  'currency',
                ].map((field) => (
                  <div key={field} className="flex flex-col">
                    <label className="text-sm font-semibold capitalize">{field}</label>
                    <input
                      type="text"
                      name={field}
                      value={form[field]}
                      onChange={handleChange}
                      required
                      className="px-3 py-1 border rounded-md"
                    />
                  </div>
                ))}

                <div className="flex justify-end col-span-2 mt-2 space-x-3">
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-1 text-black bg-gray-300 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-1 text-white bg-blue-600 rounded-md"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default DetailsTable;
