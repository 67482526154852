import React, { useEffect, useState } from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import SidebarUser from "../component/accountUser/sideBarUsers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from '../redux/constent';

const AccountUser = () => {
  const navigate = useNavigate();
  const [tokenFound, setTokenFound] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      navigate("/LoginPage", { state: true });
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/myData`, {
          headers: {
            token: ` ${token}`,
          },
        });

        const role = response?.data?.user?.role;
        setUserRole(role);
        setTokenFound(true);

        // Optional: redirect based on role
        if (role === "admin") {
          navigate("/Dashboard");
        }

      } catch (error) {
        console.error("Failed to fetch user data:", error);
        navigate("/LoginPage", { state: true });
      }
    };

    fetchUserData();
  }, [navigate]);

  if (!tokenFound) {
    return null; // Or show a loading spinner
  }

  return (
    <div>
      <Header />
      <div className="mt-[55px] md:mt-[56px] lg:mt-[75px]">
        <SidebarUser userRole={userRole} />
      </div>
      <FooterUser />
    </div>
  );
};

export default AccountUser;
