import React, { useEffect, useState } from "react";
import FooterUser from "../component/sharedComponents/footer";
import SidebarAdmin from "../component/accountAdmin/sideBarAdmin";
import HeaderDashboard from "../component/sharedComponents/HeaderDashboard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from '../redux/constent';


const Dashboard = () => {
  const navigate = useNavigate();
  const [tokenFound, setTokenFound] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/LoginPage", { state: true });
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/myData`, {
          headers: {
            token: ` ${token}`,
          },
        });

        const role = response?.data?.user?.role;
        setUserRole(role);
        setTokenFound(true);
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/LoginPage", { state: true });
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  if (loading) return null; // or a spinner

  return (
    <div>
      <HeaderDashboard />
      <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
        {userRole === "admin" ? (
          <SidebarAdmin />
        ) : (
          <div className="mt-10 text-xl font-semibold text-center text-red-600">
            ❌ You don’t have access to this page.
          </div>
        )}
      </div>
      <FooterUser />
    </div>
  );
};

export default Dashboard;
