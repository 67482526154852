    import React,{useEffect} from 'react'
    import DetailsTable from './DetailsTable';
    import { useDispatch, useSelector } from "react-redux";
    import { fetchAllOrders } from '../../redux/reducer/adminReducer';
        
    const OrdersByUsers = () => {

      const dispatch = useDispatch();
      const { allOrders, loading, error } = useSelector((state) => state.orders);
      const headers = [
        'Number',
        'Name',
        'Phone',
        'Box',
        'Link',
        'Size',
        'Count',
        'History',
        'Actions',
      ];
      useEffect(() => {
        dispatch(fetchAllOrders());
      }, [dispatch]);

      if (loading) return <p>Loading...</p>;
      if (!allOrders) return <p>not found products</p>;
      if (error) return <p>Error: {error}</p>;

      
      return (
        <div className="mx-auto overflow-x-auto rounded-xl">
          <table className="w-full">
            <thead>
              <tr className="text-sm text-black bg-custom-yellow">
                {headers.map((header, index) => (
                  <th key={index} className="px-4 py-2 font-bold text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
      
            <tbody>
              {allOrders.map((order, index) => (
                <DetailsTable
                  key={order._id || index}
                  index={index + 1}
                  details={{
                    number: index + 1,
                    Name: order.user?.name,
                    phone: order.user?.phone,
                    Box: order.ReferenceCode,
                    link: order.productLink,
                    Size: order.productSize,
                    Count: order.productCount,
                    history: order.createdAt,
                    weight: order.weight,
                    price: order.price,
                    DShippingFees: order.price,
                  }}
                  onDelete={() => alert('Deleted!')}
                  onUpdate={(data) => console.log('Updated:', data)}
                />
              ))}
            </tbody>
          </table>
        </div>
      );
      
    }

    export default OrdersByUsers
